<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="primary mx-sm-2 mx-1" dark icon>
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="currentLocale">
        <v-list-item value="en" @click="changeLocale('en')">
          {{ $vuetify.lang.t('$vuetify.languageSelectorOptions.english') }}
        </v-list-item>
        <v-list-item value="ar" @click="changeLocale('ar')">
          {{ $vuetify.lang.t('$vuetify.languageSelectorOptions.arabic') }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'KurccLocaleSelector',
  data () {
    return {
      currentLocale: 'ar'
    }
  },
  computed: {
    savedLocale () {
      return localStorage.getItem('locale')
    }
  },
  methods: {
    changeLocale (locale) {
      this.setLocale(locale)
      this.setRTL(locale)
    },
    setLocale (locale) {
      localStorage.setItem('locale', locale)
      this.$vuetify.lang.current = locale
    },
    getLocale () {
      if (this.savedLocale) {
        return this.savedLocale
      } else {
        return this.$vuetify.lang.current
      }
    },
    setRTL (locale) {
      switch (locale) {
        case 'ar':
          this.$vuetify.rtl = true
          break
        case 'en':
          this.$vuetify.rtl = false
          break
      }
    }
  },
  created () {
    this.currentLocale = this.getLocale()
    this.changeLocale(this.currentLocale)
  }
}
</script>
